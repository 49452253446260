/* eslint-disable react-refresh/only-export-components */
import { Form, redirect, useParams } from 'react-router-dom';
import { useState } from 'react';
import pensionCreditLetter from '../assets/pension_credit_letter_template.pdf';
import SplitLayout from '../components/splitLayout';
import LetterPreview from '../components/campaigns/letterPreview';
import List from '../components/campaigns/list';
import LoadingModal from '../components/loadingModal';
import { CampaignData } from '../types/global';
import Modal from '../components/modal';
import customIcons from '../utils/icons';
import Steps from '../components/campaigns/steps';
import Api from '../services/api';
import campaignSteps from '../utils/campaignSteps';
import useCampaignStore from '../components/campaigns/campaignStore';
import { getCampaign } from '../utils/campaign';

export async function action({ params }:{ params:{ campaignId: string, impactTheme: string } }) {
  const { campaignId } = params;
  try {
    const postData = getCampaign(campaignId);
    await Api.post('/api/add-campaign', postData);
    return redirect('/campaigns');
  } catch (error) {
    console.error('error:', 'failed to fetch data or redirect');
    return null;
  }
}

export default function CampaignConfirmation() {
/*
campaigns = array of all campaigns store in state store
campaign data = ALL campaign info that is collected and generated (generated being the id and created at date)
campaign information = is the campaign info that is collected from the user
*/
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const campaigns = useCampaignStore((state) => state.campaigns);
  const campaignData = campaigns.find((campaign) => campaign.id === params.campaignId) as CampaignData;
  const handleModalOpen = () => {
    setModalOpen((previousState) => !previousState);
  };
  const handleLoading = () => {
    setLoading(true);
  };
  const {
    campaignName, campaignType, snapshot, excludeHouseholds, excludeHouseholdsByMonth, campaignChannel,
  } = campaignData.campaignInformation;

  const campaign = {
    'Campaign Name': campaignName,
    'Campaign Type': campaignType,
    'LIFT Snapshot': snapshot,
  };
  const titleObject = {
    title: 'Campaign Manager',
    icon: customIcons.sendIcon,
  };

  const step = {
    icon: customIcons.previewIcon,
    step: 'Preview Letter & Send',
    info: 'By clicking send for review, you confirm the details are ready to be sent to Policy in Practice for review',
  };
  const steps = [...campaignSteps, step];
  const modalMessage = `This campaign will be be sent to Policy in Practice and drafts generated for review.
  We will be in touch to confirm details of the campaign before it is sent to residents. Would you like to proceed?`;

  const excludeHouseholdsText = (numberOfMonths: string) => (
    <>
      Households contacted in the last
      <strong>{` ${numberOfMonths?.toLowerCase()}`}</strong>
      {' '}
      will be excluded`
    </>
  );
  const includeHouseholdsText = 'Included previously contacted households';
  // function to conditionally render content based on loading state and modals
  const renderContent = () => {
    if (isModalOpen) {
      if (loading) {
        return (<LoadingModal message="loading" />);
      }

      return (
        <Modal isOpen={isModalOpen} hasCloseBtn onClose={handleModalOpen}>
          <span className="text-6xl text-pipgreen">{customIcons.mailWarningIcon}</span>
          <h2 className="text-xl font-bold text-pipgreen-dark">Please Confirm</h2>
          <p className="font-thin whitespace-wrap">{modalMessage}</p>
          <div className="w-full flex justify-between p-2">
            <button
              type="button"
              id="cancelModalButton"
              className="p-1 w-1/2 m-2 shadow-inner bg-white rounded-md text-pip-background border"
              onClick={handleModalOpen}
            >
              Cancel
            </button>
            <Form method="post" className="w-1/2" onSubmit={handleLoading} replace>
              <button
                type="submit"
                className="w-full p-1 m-2 bg-pipgreen rounded-md text-white"
              >
                Send for Review
              </button>
            </Form>
          </div>
        </Modal>
      );
    } return (
      <>
        <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Preview Campaign</h1>
        <SplitLayout leftWeight="" rightWeight="">

          <Steps
            titleObject={titleObject}
            steps={steps}
            selected={{
              campaignName, campaignType, snapshot, excludeHouseholds, campaignChannel,
            }}
          />

          <div className="flex flex-grow flex-col">
            <List campaign={campaign} />
            <p className="text-sm text-center">
              {excludeHouseholds && excludeHouseholdsByMonth ? excludeHouseholdsText(excludeHouseholdsByMonth) : includeHouseholdsText}
            </p>
            <LetterPreview src={pensionCreditLetter} />
          </div>
        </SplitLayout>
        <div className="w-full m-2 flex justify-center">
          <button
            type="button"
            className="w-1/2 p-2 bg-pipgreen rounded-md text-white"
            onClick={handleModalOpen}
          >
            Send for Review
          </button>
        </div>
      </>

    );
  };
  return (
    <div className="h-screen flex flex-col w-full p-4">
      {renderContent()}
    </div>
  );
}
