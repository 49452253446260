import customIcons from './icons';

const campaignSteps = [{
  icon: customIcons.folderOpenIcon,
  step: 'Choose name and campaign type',
  info: 'Create a name for this campaign and choose your take up campaign type eg. pension credit',
}, {
  icon: customIcons.sendIcon,
  step: 'Choose Campaign Channel',
  info: 'We currently support letter campaigns, SMS coming soon',
}];

export default campaignSteps;
