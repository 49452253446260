// import { useLoaderData } from 'react-router-dom';
import { useLoaderData } from 'react-router-dom';
import Table from '../components/table';
import { ActionColumn } from '../utils/tableButtons';
import { CampaignData } from '../types/global';
import Api from '../services/api';

export const loader = ({ params }:{ params: any }) => {
  // use the params to fetch campaign data based on the id.
  const { status } = params;
  try {
    const campaignData = Api.post('/campaigns', { params: { status } });
    return campaignData;
  } catch (error) {
    console.error('error:', error);
    return undefined;
  }
};

export default function CampaignStatus() {
  // have to coerce this to the correct type
  const data = useLoaderData() as CampaignData[];
  const tableObject: any[] = [];
  data.forEach((campaign: CampaignData) => {
    tableObject.push({
      'Campaign Name': campaign.campaignInformation.campaignName,
      'Last Updated': new Date(campaign.createdAt).toLocaleDateString(),
      'Lift Snapshot': campaign.campaignInformation.snapshot,
      'Campaign Type': campaign.campaignInformation.campaignType,
      Status: campaign.status,
    });
  });

  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Campaign Status</h1>
      <Table tableObject={tableObject} ActionColumn={ActionColumn} title="Campaigns" count="campaign" />
    </div>
  );
}
