import AppLayout from './components/app-layout/appLayout';
import AuthProvider from './components/app-layout/context/authProvider';

function App() {
  return (
    <AuthProvider>
      <AppLayout />
    </AuthProvider>
  );
}

export default App;
