export default function FormSummary({ object }: { object:any }) {
  const backgroundColour: Record<string, string> = {
    'In Debt with Council': 'bg-orange-200',
    'Tenancy Type': 'bg-blue-200',
    'Household Type': 'bg-green-200',
    Carer: 'bg-purple-200',
    'Disbaility Status': 'bg-pink-300',
  };
  return (
    <div className="flex flex-wrap lg:flex-col text-sm gap-1 m-2">
      { Object.entries(object).map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((string) => <p key={string} className={`${backgroundColour[key]} rounded-lg px-1 w-fit h-fit text-nowrap`}>{string}</p>);
        }
        return null;
      })}
    </div>
  );
}
