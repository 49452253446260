import { useState } from 'react';
import ImpactCard from './components/home/impactCard';
import TabButton from './components/home/tabButton';
import themeObject from './utils/themeFilters';

export default function Root() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div id="impact-page" className="flex flex-col w-full p-4 overflow-auto">
      <div className="flex flex-row justify-between border-b-2 items-center">
        <h1 id="campaign-header" className="m-2 p-4 text-header font-medium text-pipgreen-dark ">Take Action</h1>
      </div>
      <div id="tab-main-container" className="flex flex-col mt-4 items-start max-w-full p-2">
        <TabButton
          theme={themeObject}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ImpactCard key={themeObject[activeTab].title} theme={themeObject[activeTab]} />
      </div>
    </div>

  );
}
