/* eslint-disable @typescript-eslint/naming-convention */
import { useLoaderData, useNavigate } from 'react-router-dom';
import Table from '../components/table';
import { ActionColumn } from '../utils/tableButtons';
import { TableProps } from '../types/global';
import Api from '../services/api';
import SmallButton from '../components/smallButton';
import { adminAccess } from '../utils/authRoleWrap';

export function loader() {
  // use the params to fetch campaign data based on the id.
  try {
    const campaignData = Api.get('/api/all-campaigns');
    return campaignData;
  } catch (error) {
    console.error('error:', error);
    return undefined;
  }
}

export default function Campaigns() {
  const data = useLoaderData();
  const navigate = useNavigate();
  const newData: TableProps[] = [];
  if (Array.isArray(data)) {
    data.forEach((campaign:any) => {
      const {
        last_updated, status, snapshot, name, benefit_name, id,
      } = campaign;
      const formattedDate = new Date(last_updated).toLocaleDateString('en-GB');
      newData.push({
        'Campaign Name': name,
        'LIFT Snapshot': snapshot,
        'Campaign Type': benefit_name.split('_').join(' '),
        'Last Updated': formattedDate,
        Status: status,
        id,

      });
    });
  }
  const CreateCampaignButton = adminAccess(SmallButton);

  return (
    newData.length > 0
      ? (
        <div id="campaign-container" className="flex flex-col w-full p-4 overflow-auto">
          <div className="flex flex-row justify-between border-b-2 items-center">
            <h1 id="campaign-header" className="m-2 p-4 text-header font-medium text-pipgreen-dark ">Campaigns</h1>
            <CreateCampaignButton text="Create new campaign" isGreen onClick={() => navigate('/campaigns/new')} />
          </div>
          <Table tableObject={newData} ActionColumn={ActionColumn} title="Campaigns" count="campaign" />
        </div>
      ) : (
        <div className="flex flex-col overflow-auto sjustify-center items-center absolute inset-0 m-auto p-4 border-0 rounded-md shadow-lg bg-white h-72 w-1/2 text-center">
          <p className="m-2 p-2">You have not started any campaigns yet.</p>
          <CreateCampaignButton id="modal-new-campaign-button" text="Create new campaign" isGreen onClick={() => navigate('/campaigns/new')} />
        </div>
      )
  );
}
