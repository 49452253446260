export default function SmallButton({ text, isGreen, onClick }:{ text:string, isGreen:boolean, onClick:() => void }) {
  let backgroundColour = '';
  let hoverBackground = 'hover:bg-pipgrey-light';
  if (isGreen) {
    backgroundColour = 'bg-pipgreen text-white';
    hoverBackground = 'hover:bg-pipgreen-dark';
  }
  return (

    <button type="submit" onClick={onClick} className={`${backgroundColour} ${hoverBackground} rounded-lg pl-2 pr-2 shadow border hover:border-pipgrey-md p-1`}>
      <p className="mx-1 font-thin">{text}</p>
    </button>

  );
}
