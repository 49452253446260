/* eslint-disable react/no-array-index-key */
import { useNavigate } from 'react-router-dom';
import { IconColumn, TableProps } from '../types/global';

export default function Table({
  tableObject, ActionColumn, title, count,
}:{ tableObject:TableProps[], ActionColumn:IconColumn | null, title: string, count:string, }) {
  const navigate = useNavigate();

  const handleNavigate = (id:number) => {
    navigate(`/campaign/${id}`);
  };
  const tableHeaderSet = new Set<keyof TableProps>();
  tableObject.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (key !== 'id') tableHeaderSet.add(key);
    });
  });
  const tableHeaders = Array.from(tableHeaderSet);

  const statusMap = {
    draft: 'bg-pipstatus-default bg-opacity-50',
    'Awaiting approval': 'bg-pipstatus-warning',
    complete: 'bg-pipstatus-success',
    archived: 'bg-pipstatus-error',
    default: 'bg-white',
  };

  type StatusMapKeys = keyof typeof statusMap;

  return (
    <div className="w-full">
      <div className="bg-white rounded-lg overflow-scroll">
        <table className="min-w-max w-full table-auto font-thin ">
          <thead>
            <tr className="flex flex-row align-middle">
              <th colSpan={tableHeaders.length} className="pt-6 pb-6 px-4 text-left text-lg font-medium">{title}</th>
              <th className="mt-6 mb-6 p-2 border text-left text-xs font-thin rounded-md bg-gray-100 shadow-inner">{`${tableObject.length} ${tableObject.length > 1 ? `${count}s` : `${count}`}`}</th>
            </tr>
            <tr className="bg-gray-50 border border-t-0 text-gray-600 text-sm leading-normal">
              {tableHeaders.map((item) => (
                <th key={`${item}Header`} className="py-3 px-6 text-left">{item}</th>
              ))}
              {ActionColumn ? Object.keys(ActionColumn).map((key) => <th className="py-3 px-6 text-center" key={key}>{key}</th>) : ''}
            </tr>
          </thead>
          {/* TODO: add in overflow scroll for users in body, set max height/number of rows OR pagination */}
          <tbody>
            {tableObject.map((obj, index) => (
              <tr key={index} className="hover:bg-pipgrey-light cursor-pointer" onClick={() => { if (typeof obj.id === 'number')handleNavigate(obj.id); }}>
                {tableHeaders.map((header) => {
                  const statusClassname = obj[header] !== undefined ? statusMap[obj[header] as StatusMapKeys] : statusMap.default;
                  return (
                    <td key={`${header}-${index}`} className="py-3 px-4 text-left ">
                      <span className={`py-1 px-4 text-center rounded-xl  ${statusClassname}`}>{obj[header] ? obj[header] : '—'}</span>
                    </td>
                  );
                })}
                { ActionColumn ? Object.entries(ActionColumn).map(([key, action]) => (
                  <td className="py-3 px-6 text-center" key={key}>
                    <div className="flex item-center justify-around">
                      <button id={`${action.icon}-row-${index}`} aria-label={`${action.icon} row ${index}`} onClick={action.handleClick} key={`${key}button`} type="button" className="w-4 mr-2 transform hover:text-blue-700 hover:scale-110">{action.icon}</button>
                    </div>
                  </td>
                )) : ''}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
