interface LoadingModalProps {
  message: string;
}
export default function LoadingModal({ message }: LoadingModalProps) {
  return (
    <div className="flex justify-center items-center h-screen">
      <dialog className="h-1/2 w-1/2 flex flex-col justify-center items-center rounded-md backdrop:bg-pipgrey-light">
        <div id="spinning-wheel" className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600 m-2" />
        <p className="m-2">{message}</p>
      </dialog>
    </div>
  );
}
