/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Api from '../services/api';

export default function AdvancedDropdown({
  id, label, endpoint, selected, onChange,
}:{ id:string, label: string, endpoint:string, selected:string, onChange:Function }) {
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Api.get(endpoint);
        if (Array.isArray(result)) {
          setOptions(result);
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrorState(error);
        }
        setErrorState(new Error('Failed to fetch'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (errorState) {
    return (
      <div>
        Error: Please contact eng team
      </div>
    );
  }

  return (
    <label htmlFor={id} className="flex flex-col p-2 justify-between font-thin w-full">
      <span className="text-sm">{label}</span>
      <select id={id} name={id} value={selected || ''} onChange={(e) => onChange(id, e.target.value)} className="flex-1 p-1 rounded-md align-middle bg-slate-50 border border-pipgrey-light text-pipgrey">
        <option value="" disabled>Please select option</option>
        {options.map((option:string) => (
          <option key={`${id}-${option}`}>{option}</option>
        ))}
      </select>
    </label>
  );
}
