interface CollapseButtonProps {
  toggle: () => void; // Defining the type of 'toggle' as a function returning void
  isExpanded: boolean;
}

export default function CollapseButton({ toggle, isExpanded }: CollapseButtonProps) {
  return (
    <button
      aria-label="expand-or-collapse-menu-button"
      className={`bg-transparent relative group ${isExpanded ? 'expanded' : 'collapsed'}`}
      onClick={toggle}
      type="button"

    >
      <i className="fa-layers fa-fw icon-layer" />
    </button>

  );
}
