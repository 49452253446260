import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel,
} from 'victory';

export default function FallbackGraph() {
  const fakeData = [
    { Month: 1, Residents: 13000 },
    { Month: 2, Residents: 16500 },
    { Month: 3, Residents: 14250 },
    { Month: 4, Residents: 19000 },
    { Month: 5, Residents: 13000 },
    { Month: 6, Residents: 16500 },
    { Month: 7, Residents: 14250 },
    { Month: 8, Residents: 19000 },
    { Month: 9, Residents: 13000 },
    { Month: 10, Residents: 16500 },
    { Month: 11, Residents: 14250 },
    { Month: 12, Residents: 19000 },
  ];
  const [xKey, yKey] = Object.keys(fakeData[0]);
  const yValues = fakeData.map((d:any) => d[xKey]);
  const yTicks = yValues.map((y) => `${xKey.charAt(0).toUpperCase() + xKey.slice(1)} ${y}`);
  return (
    <div className="w-9/12 relative p-2">
      <VictoryChart domainPadding={20}>
        <VictoryAxis
    // tickValues specifies both the number of ticks and where
    // they are placed on the axis
          tickValues={yValues}
          tickFormat={yTicks}
          tickLabelComponent={<VictoryLabel angle={-45} dy={0} dx={-20} style={{ fontSize: 14, opacity: 0.5 }} />}
        />
        <VictoryAxis
          dependentAxis
    // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => (`${x / 1000}k`)}
        />
        <VictoryBar
          style={{ data: { fill: '#737373', opacity: 0.5 } }}
          data={fakeData}
          x={xKey}
          y={yKey}
        />
      </VictoryChart>
      <p className="absolute text-xl top-0 left-0 w-full h-full flex items-center justify-center text-black font-bold drop-shadow">
        No Data
      </p>
    </div>

  );
}
