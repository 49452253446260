export default class Api {
  static async get(url: string) {
    const fullUrl = `${import.meta.env.VITE_BACKEND_URL}${url}`;
    const response = await fetch(fullUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 403) {
      throw new Error('unauthorized');
    }
    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    return response.json();
  }

  static async post(url: string, data = {}) {
    const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}${url}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.status === 403) {
      throw new Error('unauthorized');
    }
    if (!response.ok) {
      throw new Error('Failed to fetch');
    }
    return response.json();
  }

  static async delete(url: string, data = {}) {
    const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}${url}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.status === 403) {
      throw new Error('unauthorized');
    }
    if (!response.ok) {
      throw new Error('Failed to fetch');
    }
    return response.json();
  }
}
