import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SideMenu from './sideMenu';

export default function AppLayout() {
  return (
    <div className="relative flex h-screen w-full ">
      <div className="bg-transparent flex ">
        <SideMenu />
      </div>
      <main className="flex flex-grow w-full overflow-auto bg-pipgrey-background">
        <Outlet />
        <ToastContainer />
      </main>
    </div>
  );
}
