import useCampaignStore from '../components/campaigns/campaignStore';
import { CampaignData, CampaignInformation } from '../types/global';

export const createCampaign = async (campaignInformation: CampaignInformation) => {
  const id = Math.random().toString(36).substring(2, 9);
  const campaign = {
    id,
    createdAt: Date.now(),
    campaignInformation,
    status: 'draft',
  };
  const { addCampaign } = useCampaignStore.getState();
  addCampaign(campaign);

  return campaign;
};

export const getCampaign = (id: string) => {
  const { campaigns } = useCampaignStore.getState();
  const campaignData = campaigns.find((campaign) => campaign.id === id) as CampaignData;
  if (!campaignData) {
    throw new Error('Campaign not found');
  }

  const { campaignName, campaignType, snapshot } = campaignData.campaignInformation;

  const postData = {
    campaign_name: campaignName,
    benefit_name: campaignType?.split(' ').join('_').toLowerCase(),
    snapshot,
    channel_name: 'print_and_post',
  };
  return postData;
};
