/* eslint-disable react/jsx-props-no-spreading */
import { Form, redirect, useLoaderData } from 'react-router-dom';
import AdvancedInput from '../components/advancedInput';
import SplitLayout from '../components/splitLayout';
import { createCampaign } from '../utils/campaign';
import customIcons from '../utils/icons';
import Steps from '../components/campaigns/steps';
import campaignSteps from '../utils/campaignSteps';
import sanitize from '../utils/sanitize';
import useCampaignStore from '../components/campaigns/campaignStore';
import Api from '../services/api';
import { CampaignInformation } from '../types/global';
import AdvancedCheckbox from '../components/advancedCheckbox';

export function loader() {
  try {
    const snapshotData = Api.get('/api/latest-snapshot');
    return snapshotData;
  } catch (error) {
    console.error('error:', error);
    return undefined;
  }
}

export async function action({ request, params }:{ request:any, params:any }) {
  const formData = await request.formData();
  const campaignInformation: CampaignInformation = {
    snapshot: formData.get('snapshot'),
    campaignType: formData.get('campaignType'),
    campaignName: formData.get('campaignName'),
    campaignChannel: formData.get('campaignChannel'),
    excludeHouseholds: formData.has('excludeHouseholds'),
    excludeHouseholdsByMonth: formData.get('excludeHouseholdsByMonth') || null,
  };
  const newCampaign = await createCampaign(campaignInformation);
  if (params.impactTheme) {
    return redirect(`/campaigns/${params.impactTheme}/new/${newCampaign.id}`);
  }
  return redirect(`/campaigns/new/${newCampaign.id}`);
}

export default function NewCampaign() {
  const { snapshot } = useLoaderData() as { snapshot: string };

  const selected = useCampaignStore((state) => state.selected);
  const setSelected = useCampaignStore((state) => state.setSelected);

  const handleChange = (event:any) => {
    const {
      name, value, type, checked,
    } = event.target;

    if (value === 'default') return;

    if (type === 'checkbox') {
      setSelected(name, checked);
    } else {
      const cleanValue = sanitize(value);
      setSelected(name, cleanValue);
    }
  };

  const dropdownData = {
    id: 'campaignType',
    options: ['Pension Credit', 'Healthy Start', 'Attendance Allowance'],
  };
  const channelDropdownData = {
    id: 'Channel',
    options: ['Letter', 'SMS'],
  };

  const titleObject = {
    title: 'Campaign Manager',
    icon: customIcons.sendIcon,
  };

  const step = {
    icon: customIcons.previewIcon,
    step: 'Preview Letter & Send',
    info: 'By clicking next, it will take you to the next page allowing you to preview the letter',
  };
  const checkboxOptions = {
    id: 'excludeHouseholds',
    label: 'Would you like to exclude households that have recently been contacted?',
    text: 'Exclude Households',
  };
  const months = [...Array(24).keys()].map((month) => (month >= 1 ? `${month + 1} Months` : 'Month'));
  const excludeHouseholdsByMonth = {
    id: 'excludeHouseholdsByMonth',
    options: months,
  };
  const steps = [...campaignSteps, step];

  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Create new campaign</h1>
      <SplitLayout leftWeight="" rightWeight="">
        <Steps steps={steps} titleObject={titleObject} selected={selected} />
        <div className="w-full mx-2 p-2 flex flex-col overflow-auto">
          <h2 className="text-2xl font-medium text-left">Choose Your Campaign</h2>
          <Form id="create-campaign" className="w-full" method="post">
            <AdvancedInput type="text" label="Campaign Name" name="campaignName" id="campaignName" onChange={(e: any) => handleChange(e)} required />
            <AdvancedInput type="dropdown" label="Campaign Type" {...dropdownData} name="campaignType" id="campaignType" value={selected.campaignType} onChange={(event:any) => handleChange(event)} required />
            <AdvancedInput type="dropdown" label="Campaign Channel" {...channelDropdownData} name="campaignChannel" id="campaignChannel" onChange={(event:any) => handleChange(event)} value="Letter" disabled />
            <input type="hidden" name="campaignChannel" value="Letter" />
            <AdvancedInput type="text" label="LIFT Snapshot" name="snapshot" id="snapshot" onChange={(e: any) => handleChange(e)} value={snapshot} disabled />
            <input type="hidden" name="snapshot" value={snapshot} />
            <AdvancedCheckbox fieldsetId="excludeHouseholds" fieldsetTitle={checkboxOptions.label} options="Exclude Households" onChange={(event:any) => handleChange(event)} selected={selected.excludeHouseholds} />
            {selected.excludeHouseholds && <AdvancedInput type="dropdown" label="For the previous" {...excludeHouseholdsByMonth} name="excludeHouseholds" id="excludeHouseholdsByMonth" onChange={(e: any) => handleChange(e)} required />}
          </Form>
        </div>
      </SplitLayout>
      <div className="w-full mt-auto mb-6 pr-4 pt-4 flex justify-center md:justify-end">
        <button type="submit" form="create-campaign" className="w-2/3 md:w-1/3 p-2 bg-pipgreen-dark rounded-lg text-pipgrey-light hover:bg-opacity-90">Next</button>
      </div>
    </div>
  );
}
