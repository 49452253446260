/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect } from 'react';
import { adminAccess, superAdminAccess } from '../utils/authRoleWrap';
import SuperAdminPanel from '../components/settings/superAdminPanel';
import Api from '../services/api';
import useAuth from '../utils/auth';
import { TableProps } from '../types/global';
import ViewerPanel from '../components/settings/viewerPanel';
import OrgAdminPanel from '../components/settings/orgAdminPanel';

export default function Settings() {
  const [organisationUsers, setOrganisationUsers] = useState<Array<TableProps>>([]);
  const [user, setUser] = useState({});
  const authUser = useAuth();

  useEffect(() => {
    const getOrganisationUsers = async () => {
      try {
        const response = await Api.get('/api/organisation-users');
        if (Array.isArray(response)) {
          console.log(response);
          const formattedResponse = response.map((responseUser) => {
            // First check for current user
            if (responseUser.email === authUser.email) {
              const formattedUser = {
                email: responseUser.email,
                'last name:': responseUser.last_name,
                'first name': responseUser.first_name,
                role: responseUser.role,
              };
              setUser(formattedUser);
            }
            const {
              email, first_name, last_name, role,
            } = responseUser;

            return {
              email,
              'first name': first_name,
              'last name': last_name,
              role,
            };
          }).filter((filteredUser) => filteredUser.email !== authUser.email);

          setOrganisationUsers(formattedResponse);
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error("False to fetch organisation's users");
        }
      }
    };

    getOrganisationUsers();
  }, [authUser.email]);

  const AdminDashboard = adminAccess(OrgAdminPanel);
  const SuperAdminDashboard = superAdminAccess(SuperAdminPanel);

  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      {Object.keys(user).length > 0 ? <ViewerPanel user={user} /> : ''}
      <AdminDashboard tableData={organisationUsers} />
      <SuperAdminDashboard />
    </div>

  );
}
