import { useState } from 'react';
import Api from '../services/api';
import customIcons from '../utils/icons';
import useToastStore from './app-layout/toastStore';

export default function ExportCSV({ id, data }: { id: string, data: any }) {
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToastStore((state) => state.showToast);

  const getCSV = async () => {
    setIsLoading(true);
    try {
      const response = await Api.post('/api/temporary-csv', { title: id, data });
      window.open(response, '_blank');
      showToast(response, 'success');
    } catch (error) {
      showToast(error instanceof Error ? error.message : 'An error occurred', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-end font-thin">
      <button onClick={getCSV} disabled={isLoading} id={`${id}-export`} type="button" className="flex flex-row justify-center items-center rounded-md pl-2 pr-2 shadow border hover:border-pipgrey-md p-1 hover:bg-pipgrey-light">
        <span>{customIcons.downloadIcon}</span>
        <p className="mx-1">{isLoading ? 'Loading...' : 'Export CSV'}</p>
      </button>
    </div>
  );
}
