import Api from '../services/api';
import { BackendUser } from '../types/global';

const getUser = async ():Promise<BackendUser> => {
  try {
    const userResponse = await Api.get('/api/session/user');
    return userResponse;
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
};

export default getUser;
