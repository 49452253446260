import { IconColumn } from '../types/global';
import customIcons from './icons';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const iconClick = (id: string) => {
  //     Api.get('/api/get-claimrefs');
};
export const ActionColumn: IconColumn = {
  Archive: { title: 'Archive', icon: customIcons.archiveIcon, handleClick: () => iconClick('archive') },
  Export: { title: 'Export', icon: customIcons.downloadIcon, handleClick: () => iconClick('export') },
};
