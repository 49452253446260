import customIcons from '../../utils/icons';

export default function PercentageChange({ percentageChange }: { percentageChange:number }) {
  let trend; let textTrend; let bgTrend; let
    icon;

  switch (true) {
    case percentageChange < 0:
      trend = 'pipstatus-error';
      textTrend = 'red-800';
      bgTrend = 'bg-red-100';
      icon = customIcons.trendingDownIcon;
      break;
    case percentageChange > 0:
      trend = 'pipstatus-success';
      textTrend = 'pipgreen';
      bgTrend = 'bg-green-100';
      icon = customIcons.trendingUpIcon;
      break;
    default:
      trend = 'pipstatus-default';
      textTrend = 'pipgrey-dark';
      bgTrend = 'bg-pigrey';
      icon = customIcons.dashIcon;
  }

  return (
    <div className={`text-xs flex flex-row px-2 ${bgTrend} bg-pipgrey-md w-fit h-fit rounded-2xl border border-pipstatus border-${trend} justify-center items-center`}>
      <i className={`text-${trend}`}>{icon}</i>
      <p className={`text-${textTrend}  font-normal ml-1 pipstatu`}>{percentageChange}</p>
    </div>
  );
}
