import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function AgentMessage({ content, index }: { content: string, index:number }) {
  return (
    <div id={`agent-message-${index}`} className="flex flex-row">
      <img src="/pip_green_logo_notext.svg" alt="Policy in Practice" className="flex justify-left align-middle overflow-hidden h-9 w-auto mt-2 mx-2 mb-2" />
      <div className="overflow-hidden ml-4 p-4 border rounded-2xl rounded-tl-none shadow-md border-gray self-baseline max-w-[75%] ">
        <div className="font-mono markdown break-words">
          <ReactMarkdown className="text-xs md:text-lg" remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        </div>
      </div>
    </div>

  );
}
