/* eslint-disable react/jsx-props-no-spreading */
import {
  LuSend as Send,
  LuHome as Home,
  LuMap as Map,
  LuLineChart,
  LuScrollText,
  LuCog,
  LuLifeBuoy,
  LuLogOut,
  LuPencil,
  LuArchive,
  LuX,
  LuFolderOpen,
  LuCalendarDays,
  LuFileCheck2,
  LuEye,
  LuInfo,
  LuCheckCircle2,
  LuMailWarning,
  LuDownload,
  LuArrowUp,
  LuPercent,
  LuMoveUpRight,
  LuTrendingUp,
  LuTrendingDown,
  LuMinus,
} from 'react-icons/lu';

interface IconWrapperProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  width: number;
  height: number;
}

function IconWrapper({
  Icon, width, height, className, ...props
}:IconWrapperProps) {
  return <Icon className={`${className} w-${width} h-${height}`} {...props} />;
}

const customIcons = {
  homeIcon: <Home />,
  sendIcon: <Send />,
  mapIcon: <Map />,
  lineChartIcon: <LuLineChart />,
  policyIcon: <LuScrollText />,
  settingsIcon: <LuCog />,
  supportIcon: <LuLifeBuoy />,
  logoutIcon: <LuLogOut />,
  editIcon: <LuPencil />,
  archiveIcon: <LuArchive />,
  closeIcon: <LuX />,
  folderOpenIcon: <LuFolderOpen />,
  calendarIcon: <LuCalendarDays />,
  fileCheck2Icon: <LuFileCheck2 />,
  previewIcon: <LuEye />,
  infoIcon: <LuInfo />,
  checkCircleIcon: <LuCheckCircle2 />,
  mailWarningIcon: <LuMailWarning />,
  downloadIcon: <LuDownload />,
  percentIcon: <LuPercent />,
  arrowUpRightIcon: <LuMoveUpRight />,
  trendingUpIcon: <LuTrendingUp />,
  trendingDownIcon: <LuTrendingDown />,
  dashIcon: <LuMinus />,
  arrowUpIcon: (props:any) => <IconWrapper Icon={LuArrowUp} {...props} />,
};

export default customIcons;
