/* eslint-disable react/jsx-props-no-spreading */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdvancedDropdown from '../advancedDropdown';
import Api from '../../services/api';
import SmallButton from '../smallButton';

export default function SuperAdminPanel() {
  const [dropdownStates, setdropdownStates] = useState({ superAdminOrgDropdown: '', superAdminRoleDropdown: '' });
  const navigate = useNavigate();

  const handleChange = (dropdownId: string, value: string) => {
    if (value === 'default') return;
    setdropdownStates((previous) => ({ ...previous, [dropdownId]: value }));
  };

  const changeRoleOrganisations = (role: string | null, organisation: string | null) => {
    Api.post('/api/view-as', { role_name: role, display_name: organisation });
    return navigate('/');
  };

  return (
    <div className="border border-pipgrey-light rounded-md p-2">
      <h3 className="ml-4">Super Admin Panel</h3>
      <div className="flex flex-col md:flex-row justify-between ">
        <div className="m-2 flex flex-col md:w-1/2 h-1/2 flex-1">
          <AdvancedDropdown id="superAdminOrgDropdown" label="Organisations" onChange={handleChange} selected={dropdownStates.superAdminOrgDropdown} endpoint="/api/organisation-names" />
          <AdvancedDropdown id="superAdminRoleDropdown" label="Roles" onChange={handleChange} selected={dropdownStates.superAdminRoleDropdown} endpoint="/api/role-names" />
        </div>
        <div id="superAdminPanel-button-container" className="m-2 p-2 md:mt-auto flex flex-row md:flex-col md:w-1/2  gap-2 h-1/2 md:items-end items-center">
          <SmallButton
            isGreen={false}
            text="Reset"
            onClick={() => changeRoleOrganisations(null, null)}
          />
          <SmallButton
            isGreen
            text="Change Roles/Organisations"
            onClick={() => changeRoleOrganisations(dropdownStates.superAdminRoleDropdown, dropdownStates.superAdminOrgDropdown)}
          />

        </div>

      </div>
    </div>
  );
}
