export default function AdvancedCheckbox({
  fieldsetId, fieldsetTitle, options, onChange, selected,
}:{ fieldsetId: string, fieldsetTitle: string, options: string[] | string, onChange: (event: any) => void, selected: boolean }) {
  // lower case word, replace the first letter with lower case and the rest with upper case
  const camelCase = (word:string) => word.toLowerCase().replace(
    /(?:^\w|[A-Z]|\b\w)/g,
    (text, index) => (index === 0 ? text.toLowerCase() : text.toUpperCase()),
    // remove sppaces
  ).replace(/\s+/g, '');

  const multipleOptions = (checkboxOptions: string[]) => checkboxOptions.map((option: string, index: number) => (
    <label htmlFor={`${option}-${index}`} key={`${option}-checkbox`} className="">
      <div className="flex flex-row ">
        <input
          type="checkbox"
          id={`${option}-${index}`}
          name={camelCase(option)}
          checked={selected}
          onChange={onChange}
          defaultChecked={false}
        />
        <p className="pl-2">{option}</p>
      </div>
    </label>
  ));

  const singleOption = (checkboxOptions: string) => (
    <label htmlFor={checkboxOptions} className="">
      <div className="flex flex-row ">
        <input
          type="checkbox"
          name={camelCase(checkboxOptions)}
          id={checkboxOptions}
          onChange={onChange}
          defaultChecked={false}

        />
        <p className="pl-2">{options}</p>
      </div>
    </label>
  );
  return (
    <fieldset id={fieldsetId} className="justify-between w-full font-thin p-2">
      <legend className="">{fieldsetTitle}</legend>
      {Array.isArray(options) ? multipleOptions(options) : singleOption(options)}
    </fieldset>
  );
}
