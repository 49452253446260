import { useLoaderData } from 'react-router-dom';
import Api from '../services/api';
import { PensionCreditCampaignSummary } from '../types/global';
import HeadlineCard from '../components/home/headlineCard';
import SplitLayout from '../components/splitLayout';
import BarGraph from '../components/home/barGraph';
import FallbackGraph from '../components/home/fallbackGraph';

export function loader({ params }:{ params:{ id: number } }) {
  const { id } = params;
  try {
    const campaignData = Api.get(`/api/campaign/${id}`);
    return campaignData;
  } catch (error) {
    console.error('error:', 'Failed to fetch data');
    return undefined;
  }
}

export default function CampaignSummary() {
  const data:any[] = [];
  const campaignData = useLoaderData() as PensionCreditCampaignSummary;
  const firstDate = `20${campaignData.snapshot.slice(2, 4)}-${campaignData.snapshot.slice(0, 2)}-01`;
  const date = new Date(firstDate).toLocaleString('default', { month: 'long', year: 'numeric' });

  const percentageWhoClaimed = Math.round(((campaignData.campaign_summary.number_of_households_claiming_something / 1006) * 100) - 1);

  return (
    <div id="campaign-container" className="flex flex-col w-full p-4 overflow-auto">
      <div className="flex flex-row justify-between border-b-2 items-center">
        <h1 id="campaign-summary-header" className="m-2 p-4 text-header font-medium text-pipgreen-dark ">Campaign Report</h1>
      </div>
      <div className="h-full">
        <div className="flex flex-col md:flex-row my-2 mx-2 p-2 justify-between">
          <div className="flex flex-col my-2 mx-2 p-2 justify-between">
            <h2 className="font-semibold text-lg">{campaignData.name}</h2>
            <p>{`Data from: ${date}`}</p>
          </div>
          <div className="flex flex-col items-end">
            <button
              type="button"
              className="rounded-lg shadow hover:bg-pipgrey-light p-2 w-fit "
            >
              Export Data
            </button>
          </div>
        </div>

        <SplitLayout leftWeight="" rightWeight="border-none shadow-none">
          <div className="flex flex-col">
            <div className="flex flex-col lg:flex-row">
              <HeadlineCard
                text="Total letters sent"
                number={1006}
              />
              <HeadlineCard
                text="Number of households who claimed something"
                number={campaignData.campaign_summary.number_of_households_claiming_something}
                percentageChange={percentageWhoClaimed}
              />
              <HeadlineCard
                text="Number of carer households"
                number={campaignData.campaign_summary.number_of_carer_households}
              />

            </div>
            <div>
              {data && data.length > 0 ? <BarGraph data={data} /> : <FallbackGraph />}
            </div>

          </div>
          <div className="flex flex-col w-full">

            <HeadlineCard
              text="Annual Impact"
              number={campaignData.campaign_summary.annual_pc_value.toLocaleString()}
            >
              <div className="font-normal text-xs">some text</div>
            </HeadlineCard>
            <HeadlineCard
              text="Lifetime Impact"
              number={campaignData.campaign_summary.lifetime_pc_value.toLocaleString()}
            />
          </div>
        </SplitLayout>
      </div>

    </div>
  );
}
