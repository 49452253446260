import { TableProps } from '../../types/global';
import InformationText from '../InformationText';
import SplitLayout from '../splitLayout';
import Table from '../table';

export default function OrgAdminPanel({ tableData }:{ tableData:TableProps[] }) {
  return (
    <SplitLayout leftWeight="md:w-4/12" rightWeight="md:w-8/12">
      <InformationText title="Your Team" subtitle="Edit user roles or archive users" />
      <Table tableObject={tableData} ActionColumn={null} title="Your Team" count="User" />
    </SplitLayout>
  );
}
