import { Link } from 'react-router-dom';

export default function Support() {
  return (
    <div className="flex flex-col w-full overflow-auto justify-center items-center p-4">
      <div className="flex flex-col w-full h-1/2 overflow-auto justify-between items-center p-4">
        <h1 className="text-xl p-8">What kind of support are you looking for? </h1>
        <div className="flex flex-col w-full h-full justify-around items-center md:flex-row">
          <div className="flex justify-center items-center bg-pipgreen w-1/3 h-1/4 rounded-lg hover:shadow-xl hover:bg-pipgreen-dark">
            <Link className="text-white" to="product">Product</Link>
          </div>
          <div className="flex justify-center items-center bg-pipgrey w-1/3 h-1/4 rounded-lg hover:shadow-xl hover:bg-pipgrey-dark">
            <Link className="text-white" to="policy">Policy</Link>
          </div>
        </div>
      </div>
    </div>

  );
}
