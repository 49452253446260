import { create } from 'zustand';

interface DropdownState {
  selected: { [title: string]: { [key: string]: string[]; }; };
  setSelected: (title: string, dropdownKey: string, options: string[]) => void;
}

const useMultiCheckboxDropdownStore = create<DropdownState>((set) => ({
  selected: {},
  setSelected: (title, dropdownKey, options) => set((state) => ({
    selected: { ...state.selected, [title]: { ...state.selected[title], [dropdownKey]: options } },
  })),
}));

export default useMultiCheckboxDropdownStore;
