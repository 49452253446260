/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
import { ComponentType, ElementType, FC } from 'react';
import useAuth from './auth';
import Role from '../types/enums';
// Role component wrapper needs versatile type so that it can be used for all components while still being type safe
const withRoleAccess = (allowedRoles: string[]) => <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P> | ElementType => {
  const WithRoleAccess: FC<P> = (props) => {
    const authUser = useAuth();

    if (allowedRoles.includes(authUser.role)) {
      return <WrappedComponent {...props} />;
    }
    return null;
  };

  return WithRoleAccess;
};

const withSuperAdminAccess = () => <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P> | ElementType => {
  const WithRoleAccess: FC<P> = (props) => {
    const authUser = useAuth();

    if (authUser.role === Role.SuperAdmin) {
      return <WrappedComponent {...props} />;
    }
    return null;
  };

  return WithRoleAccess;
};

export const adminAccess = withRoleAccess([Role.SuperAdmin, Role.OrganisationAdmin]);
export const superAdminAccess = withSuperAdminAccess();

export default withRoleAccess;
