import { create } from 'zustand';
import { CampaignData, CampaignInformation } from '../../types/global';

interface CampaignStoreState {
  campaigns: CampaignData[];
  selected: CampaignInformation;
  setCampaigns: (campaigns: CampaignData[]) => void;
  addCampaign: (campaign: CampaignData) => void;
  setSelected: (title: string, value: string | boolean) => void;
}

const useCampaignStore = create<CampaignStoreState>((set) => ({
  campaigns: [],
  selected: {
    campaignType: '',
    campaignChannel: 'Letter',
    campaignName: '',
    snapshot: '',
    excludeHouseholds: false,
  },
  setCampaigns: (campaigns) => set(() => ({
    campaigns,
  })),
  addCampaign: (campaign) => set((state) => ({
    campaigns: [campaign, ...state.campaigns],
  })),
  setSelected: (title, value) => set((state) => ({
    selected: { ...state.selected, [title]: value },
  })),
}));

export default useCampaignStore;
