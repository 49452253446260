import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel,
} from 'victory';

type DataPoints = {
  [key:string]:any
};

export default function BarGraph({ data }: { data: DataPoints[] }) {
  if (data.length === 0) {
    return <div>No data available</div>;
  }
  const [xKey, yKey] = Object.keys(data[0]);
  const yValues = data.map((d) => d[xKey]);
  const yTicks = yValues.map((y) => `${xKey.charAt(0).toUpperCase() + xKey.slice(1)} ${y}`);

  return (
    <div className="w-60">
      <VictoryChart domainPadding={20}>
        <VictoryAxis
    // tickValues specifies both the number of ticks and where
    // they are placed on the axis
          tickValues={yValues}
          tickFormat={yTicks}
          tickLabelComponent={<VictoryLabel angle={-45} dy={0} dx={-20} style={{ fontSize: 10 }} />}
        />
        <VictoryAxis
          dependentAxis
    // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => (`${x / 1000}k`)}
        />
        <VictoryBar
          style={{ data: { fill: '#c43a31' } }}
          data={data}
          x={xKey}
          y={yKey}
        />
      </VictoryChart>
    </div>
  );
}
