import { InformationProps } from '../../types/global';
import AdvancedInput from '../advancedInput';
import InformationText from '../InformationText';
import SplitLayout from '../splitLayout';

export default function ViewerPanel({ user }:{ user:Record<string, string> }) {
  const titleInformation: InformationProps = {
    title: 'Personal Information',
    subtitle: 'Update your personal information',
  };
  return (
    <SplitLayout leftWeight="md:w-4/12" rightWeight="flex-wrap md:w-8/12">
      <InformationText title={titleInformation.title} subtitle={titleInformation.subtitle} />
      {Object.entries(user).map(([key, value]) => (<AdvancedInput key={`${key}-${user}`} type="text" id={key} label={key} value={value} disabled />
      ))}
    </SplitLayout>
  );
}
