import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider, createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import App from './App';
import './index.css';
import Login, { loader as loginLoader } from './routes/login';
import { loginAction } from './components/login/loginForm';
import Root from './root';
import ErrorPage from './pages/errorPage';
import CampaignSummary, { loader as summaryLoader } from './routes/campaignSummary';
import Settings from './routes/settings';
import NewCampaign, { action as createCampaign, loader as snapshotLoader } from './routes/newCampaign';
import CampaignConfirmation, { action as campaignAction } from './routes/campaignConfirmation';
import CampaignStatus, { loader as campaignStatusLoader } from './routes/campaignStatus';
import Campaigns, { loader as campaignsLoader } from './routes/campaigns';
import Support from './routes/support';
import LoadingModal from './components/loadingModal';
import SupportPolicy from './routes/supportPolicy';
import SupportProduct from './routes/supportProduct';
import { superAdminAccess } from './utils/authRoleWrap';

if (!window.location.hostname.includes('localhost')) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException as Error || null;
      if (error.message && error.message.includes('/backend/auth/*')) {
        return null;
      }
      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/lift-2\.co.uk\/*/, /^https:\/\/lift-2-staging\.co.uk\/*/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const AdminSupportRoute = superAdminAccess(SupportPolicy);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([{
  path: '/',
  element: <App />,
  id: 'root',
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Root />,
      errorElement: <ErrorPage />,
    }, {
      path: 'settings',
      element: <Settings />,
      errorElement: <ErrorPage />,
    },
    {
      path: 'campaigns',
      element: <Campaigns />,
      loader: campaignsLoader,
      errorElement: <ErrorPage />,
    }, {
      path: 'campaigns/new',
      element: <NewCampaign />,
      action: createCampaign,
      loader: snapshotLoader,
      errorElement: <ErrorPage />,
    }, {
      path: 'campaigns/new/:campaignId',
      action: campaignAction,
      element: <CampaignConfirmation />,
      errorElement: <ErrorPage />,
    }, {
      path: 'campaigns/:impactTheme/new',
      element: <NewCampaign />,
      action: createCampaign,
      loader: snapshotLoader,
      errorElement: <ErrorPage />,
    }, {
      path: 'campaigns/:impactTheme/new/:campaignId',
      action: campaignAction,
      element: <CampaignConfirmation />,
      errorElement: <ErrorPage />,
    }, {
      path: 'campaigns/:status',
      element: <CampaignStatus />,
      loader: campaignStatusLoader,
      errorElement: <ErrorPage />,
    }, {
      path: 'campaign/:id',
      element: <CampaignSummary />,
      loader: summaryLoader,
      errorElement: <ErrorPage />,
    }, {
      path: '/support',
      element: <Support />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/support/policy',
      element: <AdminSupportRoute />,
      errorElement: <ErrorPage />,
    }, {
      path: '/support/product',
      element: <SupportProduct />,
      errorElement: <ErrorPage />,
    }],
}, {
  path: 'login',
  element: <Login />,
  loader: loginLoader,
  action: loginAction,
  errorElement: <ErrorPage />,
}]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <RouterProvider router={router} fallbackElement={<LoadingModal message="initial load" />} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
