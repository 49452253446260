import { Theme } from '../../types/global';

export default function TabButton({ theme, activeTab, setActiveTab }:{ theme:Theme[], activeTab:any, setActiveTab:any }) {
  return (
    <div id="tab-header" className="flex list-none ml-2">
      {theme.map((tab:Theme, index:number) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={`${index === activeTab && ' bg-pipgreen shadow-[0_0_10px_rgba(0,0,0,0.1)] border-none text-white '}flex-1 cursor-pointer p-2 text-center rounded-t-lg bg-pipbg-mobile border-t-2 border-x-2 border-pipgrey-light`} key={`${tab.title}-${index}`}>
          <button onClick={() => setActiveTab(index)} type="button" id={tab.title}>
            {tab.title}
          </button>
        </li>
      ))}
    </div>
  );
}
