import { IconButton, CampaignInformation, StepsProps } from '../../types/global';
import InformationText from '../InformationText';

// eslint-disable-next-line react/require-default-props
export default function Steps({
  steps, titleObject, selected,
}: { steps: StepsProps[], titleObject: IconButton, selected: CampaignInformation }) {
  const stepDone = (campaignState: CampaignInformation, step: string) => {
    if (step === 'Choose name and campaign type' && campaignState.campaignName !== '') {
      return 'text-pipgreen';
    }
    if (step === 'Choose Campaign Channel') {
      return 'text-pipgreen';
    }
    return 'text-pipgrey';
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const fadeStep = (campaignState: CampaignInformation, step: string) => {
    if (step === 'Choose name and campaign type' && campaignState.campaignType !== '') {
      return 'opacity-60';
    }
    if (step === 'Choose Campaign Channel') {
      return 'opacity-60';
    }
    return '';
  };

  return (
    <div className="overflow-auto">
      <div className="flex flex-row items-center ">
        <span className="m-2 p-2 text-2xl text-pipgreen">{titleObject.icon}</span>
        <h2 className="text-2xl font-medium">{titleObject.title}</h2>
      </div>
      <p className="m-2 p-2 font-thin">
        This tool will enable you to contact your residents and run benefit take up campaigns.
        {' '}
      </p>
      {selected && steps.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${item.step}-${index}`} className={`flex flex-row items-center mx-2 px-2 ${fadeStep(selected, item.step)}`}>
          <div className={`w-fit h-fit border-2 rounded p-4 text-xl ${stepDone(selected, item.step)}`}>{item.icon}</div>
          <InformationText title={item.step} subtitle={item.info} />
        </div>
      ))}
    </div>
  );
}
